globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"c52e5cd3183814dd3a9bd1d2234765b3847e2322"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import * as Sentry from '@sentry/nextjs';
import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();

Sentry.init({
  environment: publicRuntimeConfig.stage,
  dsn: publicRuntimeConfig.sentryDsnFe,
  tracesSampleRate: 1.0,
});
